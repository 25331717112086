// Project List function
document.addEventListener('DOMContentLoaded', function(event) {
  const button = document.getElementById('project-button');
  const projectsDiv = document.getElementById('projects');

  if (button) {
    button.addEventListener('click', function(event) {
      // Toggle the display of the projects div
      if (projectsDiv.style.display === 'none' || projectsDiv.style.display === '') {
        projectsDiv.style.display = 'block';
      } else {
        projectsDiv.style.display = 'none';
      }
    });
  }
});
