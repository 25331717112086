$(document).ready(function() {
  // Unified hover function for all summary-link
  $('.summary-container .summary-link').hover(
    function() {
      const color = $(this).data('color');
      $('.summary-container').css('background-color', color);
      $('.summary-container .summary-link img').addClass('filter-to-white');
    },
    function() {
      $('.summary-container').css('background-color', '');
      $('.summary-container .summary-link img').removeClass('filter-to-white');
    }
  );
});
