$(document).ready(function() {
  var currentUrl = window.location.href;

  // Optimize URL replacement logic with jQuery for French link
  var newHrefFr = currentUrl.includes('/en/') ? currentUrl.replace('/en/', '/fr/') : currentUrl + 'fr/';
  $('#languageLinkFr').attr('href', newHrefFr);

  // Optimize URL replacement logic with jQuery for English link
  var newHrefEn = currentUrl.includes('/fr/') ? currentUrl.replace('/fr/', '/en/') : currentUrl + 'en/';
  $('#languageLinkEn').attr('href', newHrefEn);

  // Menu toggler click event
  $('.menu-toggler').click(function() {
    $('.menu-container').toggleClass('active');
    $(this).toggleClass('toggled');
    if (!currentUrl.includes("architecture") &&
        !currentUrl.includes("interiors") &&
        !currentUrl.includes("lighting") &&
        !currentUrl.includes("urban")) {
      $('.navbar-brand .logo').toggleClass('inverted');
    } // Invert logo color
  });

  // Unified hover function for all menu links
  $('.services-container .menu-link').hover(
    function() {
      const color = $(this).data('color');
      $('.menu-container').css('background-color', color);
      $('.services-container .menu-link, .about-container .menu-link').css('color', '#aaa');
      $(this).css('color', 'white');
      $('i.social-media-icon').css('color', color);
      $('.logo').css('filter', 'invert(0)');
      $('svg.social-media-icon').css('fill', color);
    },
    function() {
      $('.menu-container').css('background-color', '');
      $('.services-container .menu-link, .about-container .menu-link').css('color', '');
      $(this).css('color', '');
      $('i.social-media-icon').css('color', '');
      $('.logo').css('filter', '');
      $('svg.social-media-icon').css('fill', '');
    }
  );
});
